@import '../includes';
@import '../settings/colors';

.table-with-white-bg,
.table-with-grey-bg {
  .mat-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid $stock-hint;
    border-radius: 8px;
    overflow: hidden;

    tr {
      th {
        @include font(13px, 16px, 600, $text-primary);
        text-transform: uppercase;
        background-color: $white-primary;
      }

      td {
        @include font(13px, 20px, 400, $text-tertiary);
      }

      &.mat-header-row {
        height: 40px;

        .mat-header-cell {
          border-bottom: 1px solid $stock-hint;
        }
      }

      &.mat-row {
        height: 48px !important;
        background-color: $white-primary;

        &.example-detail-row {
          height: 0 !important;

          td {

            height: 0 !important;
            padding: 0 !important;
            border: 0 !important;
          }
        }

        &.example-element-row td {
          border-bottom-width: 0;
        }

        .mat-cell {
          border-bottom-color: --border-light-grey;
        }

        &:hover,
        &.active {
          background-color: $bg-light-white;
        }

        // &:last-child .mat-cell {
        //   border-bottom: none;
        // }
      }
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type {
      padding-left: 12px;
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type {
      padding: 8px 12px;
    }
  }
}

.table-with-grey-bg .mat-table tr th {
  background-color: $background-primary;
}

.table-data {
  @include font(13px, 20px, 600, $text-primary);
}

/* Table selection box styles here */
.table-action {
  padding: 6px 12px;
  background-color: $white-primary;
  border-radius: 5px;

  .count {
    @include size(30px);
    @include font(13px, 30px, 700, $text-primary);
    border-radius: 6px;
    background: $white-lilac;
  }

  .page-title1 {
    @include font(13px, 18px, 700, $black-primary);
  }

  .info {
    @include font(14px, 20px, 500, $text-primary);
  }

  &-grey {
    background-color: $background-light-blue;

    .count {
      background: $white-primary;
    }
  }
}

/* Strategic priorities table styles here */
.strategic-priorities-table-responsive {
  height: calc(100vh - 202px) !important;

  tr {

    th,
    td {
      &:nth-child(1) {
        width: 60px;
      }

      &:nth-child(2) {
        @include minMaxWidth(100px);
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        @include minMaxWidth(150px);
      }

      &:last-child {
        width: 100px;
        text-align: center;
        padding: 9px 12px;
      }

      .more-actions,
      .view-action {
        color: $grey-lighten-7;
        display: none;

        &:hover {
          background-color: $black-color-05;
        }
      }
    }

    &:hover {

      .more-actions,
      .view-action {
        display: inline-table;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table;
      background-color: $black-color-05;
    }
  }

  &.table-select-item {
    height: calc(100vh - 256px) !important;
  }
}

.strategic-priorities-table-responsive-no-select {
  table {
    tr {

      td,
      th {

        &:nth-child(1) {
          @include minMaxWidth(100px);
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          @include minMaxWidth(150px);
        }

        &:last-child {
          width: 100px;
          text-align: center;
          padding: 9px 12px;
        }

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }
}

/* Legal entities table styles here */
.legal-entity-table {
  height: calc(100vh - 330px) !important;

  tr {

    th,
    td {

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @include minMaxWidth(150px);
      }

      &:nth-child(4) {
        @include minMaxWidth(130px);
      }

      &:nth-child(7),
      &:nth-child(9) {
        @include minMaxWidth(120px);
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(8) {
        @include minMaxWidth(100px);
      }

      &:nth-child(10) {
        width: 100px;
      }

      &:last-child {
        text-align: center;
      }

      .more-actions {
        color: $grey-lighten-7;
        display: none;

        &:hover {
          background-color: $black-color-05;
        }
      }
    }

    &:hover {
      .more-actions {
        display: inline-table;
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table;
      background-color: $black-color-05;
    }
  }
}

// Project Steps Demand Table
.demand-other-tab {
  .dashboard-box {
    .view-projects-tab {
      .mat-tab-body {
        .table-section {
          height: auto;
        }
      }
    }
  }
}

// Legal Entity Table
.entityscreen {
  .admin-entity-billing-table-responsive {
    height: calc(100vh - 444px) !important;
  }
}

// Legal Entity Table
.entityscreen {
  .admin-entity-billing-table-responsive {
    height: calc(100vh - 444px) !important;
  }
}

.reference-document {
  .table {
    .sow-document {
      height: calc(100vh - 500px) !important;
    }
  }
}

.project-manage-invocing-tab {
  .table-section {
    height: calc(100vh - 338px) !important;
  }
}

.selection-group {
  .result-table {
    .mat-table {
      tr {

        th,
        td {
          &:first-child {
            width: 80px;
            padding: 9px 12px;
          }

          &:nth-child(2) {
            @include minMaxWidth(50px);
          }

          &:nth-child(3),
          &:nth-child(6),
          &:nth-child(7) {
            @include minMaxWidth(150px);
          }

          &:nth-child(4),
          &:nth-child(5) {
            @include minMaxWidth(100px);
          }

          &:nth-child(8) {
            @include minMaxWidth(150px);
          }

          &:last-child {
            width: 100px;
            padding: 9px 12px;
            text-align: center;
          }
        }

        &:hover {
          .selection-drag {
            display: inline-table !important;
          }

          .more-actions {
            display: inline-table !important;
          }
        }

      }

      .selection-drag {
        color: $grey-lighten-7;
        display: none;
      }

      .more-actions {
        color: $grey-lighten-7;
        display: none;

        &:hover {
          background-color: $black-color-05;
        }
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table !important;
      background-color: $black-color-05;
    }
  }

  .result-table-without-score{
    .mat-table {
      tr {

        th,
        td {
          &:first-child {
            width: 80px;
            padding: 9px 12px;
          }

          &:nth-child(2) {
            @include minMaxWidth(50px);
          }

          &:nth-child(3),
          &:nth-child(7),
          &:nth-child(8) {
            @include minMaxWidth(150px);
          }

          &:nth-child(5),
          &:nth-child(6) {
            @include minMaxWidth(100px);
          }

          &:nth-child(9) {
            @include minMaxWidth(150px);
          }

          &:last-child {
            width: 100px;
            padding: 9px 12px;
            text-align: center;
          }
        }

        &:hover {
          .selection-drag {
            display: inline-table !important;
          }

          .more-actions {
            display: inline-table !important;
          }
        }

      }

      .selection-drag {
        color: $grey-lighten-7;
        display: none;
      }

      .more-actions {
        color: $grey-lighten-7;
        display: none;

        &:hover {
          background-color: $black-color-05;
        }
      }
    }

    .more-actions[aria-expanded='true'] {
      display: inline-table !important;
      background-color: $black-color-05;
    }
  }
}

// Table Header Sticky

.currency-header-sticky {
  height: calc(100vh - 356px) !important;

  .currency-conversion {
    height: auto !important;
    max-height: calc(100vh - 406px);
    border: 1px solid $stock-hint;
    border-radius: 8px;
  }

  .mat-table {
    border: none;
    overflow: auto !important;

    tr {
      &:last-of-type {
        border-bottom: none;

        td {
          border-bottom: none;
        }
      }
    }
  }
}

.impact-tracing-table {
  table {
    th {
      background-color: $background-primary !important;
    }
  }
}

.impact-tracing-table {
  overflow-x: auto;

  table {
    width: auto !important;

    .mat-header-row {
      height: 40px;
      background-color: $background-primary !important;

      .mat-header-cell {
        padding: 12px;
        color: $text-primary-4 !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        text-transform: uppercase;
        min-width: 100px;
        max-width: 100px;
        border-bottom-style: none;

        &:first-of-type {
          padding-left: 16px !important;
          min-width: 150px;
          max-width: 150px;
        }

      }
    }

    .mat-row {
      align-items: flex-start;

      &:last-of-type {
        td {
          &:last-of-type {
            .more-actions {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }

      td {

        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          border-radius: 50%;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        td {
          background-color: $background-primary !important;
        }

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }

    .mat-cell {
      padding: 12px;
      min-width: 100px;
      max-width: 100px;
      word-break: break-all;
      border-bottom-style: none;

      &:first-of-type {
        padding-left: 16px !important;
        min-width: 150px;
        max-width: 150px;
      }
    }

    tr {
      .mat-cell {
        padding: 12px;
      }

    }
  }
}

.contract-container {
  table {
    border: 1px solid $stock-hint;
    border-radius: 5px;
    thead {
      position: sticky;
      top: 0;

      th {
        &.mat-header-cell:first-of-type {
          padding-left: 16px;
        }

        background-color: $background-primary !important;
        border-right: 1px solid $white-02;
        color: $text-primary !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        text-transform: uppercase;
      }
    }


    tr {

      th,
      td {

        &:nth-child(1) {
          @include minMaxWidth(130px);
        }

        &:nth-child(2) {
          @include minMaxWidth(75px);
        }

        &:nth-child(3) {
          @include minMaxWidth(80px);
        }

        &:nth-child(4) {
          @include minMaxWidth(100px);
        }

        &:last-child {
          text-align: center;
        }

        .more-actions {
          color: $grey-lighten-7;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        .more-actions {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }
  }
}

.color-table {
  height: calc(100vh - 400px) !important;
  overflow: auto;

  table {

    // .mat-header-row {
    //   height: 40px;
    //   background-color: $background-primary !important;

    //   .mat-header-cell {

    //     &:first-of-type {
    //       min-width: 150px;
    //       max-width: 150px;
    //     }

    //   }
    // }


    .color {
        .color-badge {
          border-radius: 50%;
          height: 20px;
          width: 20px;
          border: 1px solid;
          display: block;
          margin-right: 2px;
        }
    }

    .mat-row {
      align-items: flex-start;

      td {
        .more-actions,
        .view-action {
          color: $grey-lighten-7;
          border-radius: 50%;
          display: none;

          &:hover {
            background-color: $black-color-05;
          }
        }
      }

      &:hover {
        td {
          background-color: $background-primary !important;
        }

        .more-actions,
        .view-action {
          display: inline-table;
        }
      }

      .more-actions[aria-expanded='true'] {
        display: inline-table;
        background-color: $black-color-05;
      }
    }

    tr {
      .mat-cell {
        padding: 12px;
      }

    }
  }

}
